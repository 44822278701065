<template>
    <!-- <SectionBanner /> -->
    <!-- <SectonArticle :data="dataNews" @currentPage="getCurrentPage" /> -->
    <SectionChooseUs />
    <SectionThumbs />
    <div class="container mt-15 lg:mt-24">
        <GlobalWrapperContactFooter />
    </div>
</template>

<script setup lang="ts">
import SectionBanner from './SectionBanner.vue'
import SectonArticle from './SectionArticle.vue'
import SectionChooseUs from './SectionChooseUs.vue'
import SectionThumbs from './SectionThumbs.vue'
const { getNewsForAgency } = useAgency()
const currentPage = ref(useRoute().query.page ?? 1)
const dataNews = ref()
const LIMIT = 12
const getCurrentPage = async (page) => {
    dataNews.value = await getNewsForAgency({ query: { limit: LIMIT, page: page } })
}
onMounted(async () => {
    dataNews.value = await getNewsForAgency({ query: { limit: LIMIT, page: currentPage.value } })
})
useDirectusCollectionSeo('agency_index', {})
</script>

<style></style>
