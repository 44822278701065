<template>
    <section>
        <div class="container my-16">
            <div class="text-blacks-100 text-sm col-flex mx-auto">
                <GlobalBreadcrumb :breadcrumb="breadcrumb" isLight />
            </div>
            <div class="col-flex gap-6 max-w-180 mx-auto">
                <v-content
                    tag="h2"
                    option_key="agency_title_why_choose_us"
                    class="text-36px lg:text-48px sm:leading-12 lg:leading-16 text-blacks-100 font-semibold w-full text-center"
                >
                </v-content>
                <v-content
                    option_key="agency_sub_title_why_choose_us"
                    class="text-blacks-70 lg:text-xl lg:text-center w-full"
                >
                </v-content>
                <nuxt-link
                    :to="'tim-noi-mua' + '#section-contact-us'"
                    class="btn-arrow btn-effect-dark w-max flex items-center text-white bg-blacks-100 px-12 mt-5 lg:mt-10 h-15"
                >
                    <p>Đăng ký đại lý</p>
                </nuxt-link>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
const breadcrumb = ref([
    {
        title: 'Trang chủ',
        url: '/'
    },
    {
        title: 'Dành cho đại lý'
    }
    // {
    //     title: !modelCategory.category.parent ? '' : modelCategory.category?.title,
    //     url: renderData.model?.url
    // }
])
</script>

<style></style>
